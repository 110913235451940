.percentage-bar {
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 20px;
  overflow: hidden;
  margin: 10px 0;
  border: 2px solid #036B41;
}

.percentage-bar-fill {
  height: 25px;
  background-color: #036B41;
  text-align: center;
  line-height: 25px;
  color: white;
  border-radius: 20px 0 0 20px;
  font-size: 16px;
}